import React from "react";
import {
  AboutContainer,
  InnerContainer,
  HeaderContainer,
  Title,
  ContentGrid,
  IntroText,
  SkillsContainer,
  SkillsList,
} from "./styles/About.styles";
import { ProjectsButton } from "./styles/Home.styles";
import { HiArrowNarrowRight } from "react-icons/hi";
import { scrollToSection } from "../util/scrollToSection";

const About = () => {
  return (
    <AboutContainer id="about">
      <InnerContainer>
        <HeaderContainer>
          <Title>
            <p>ABOUT ME</p>
          </Title>
          <div>
            Below you will find more information about me, what I do, and my
            current skills mostly in terms of web development.
          </div>
        </HeaderContainer>
        <ContentGrid>
          <IntroText>
            <h2>Get to know me!</h2>
            <p>
              Taming bugs and wrangling code one commit at a time. While "cowboy
              coding" might sound a little wild, I like to think of it as adding
              some creative flair to problem-solving.
            </p>
            <p>
              Armed with a keyboard and a passion for crafting clean, scalable
              code, I thrive on solving complex problems and delivering
              efficient, user-friendly applications. I’m dedicated to meeting
              deadlines with precision and leaving a trail of successful
              projects behind. Let’s connect and create something impactful!
            </p>
            <ProjectsButton onClick={() => scrollToSection("contact")}>
              CONTACT
              <span className="arrow-icon">
                <HiArrowNarrowRight />
              </span>
            </ProjectsButton>
            {/* Button stays in IntroText */}
          </IntroText>
          <SkillsContainer>
            <h3>My Skills</h3>
            <SkillsList>
              <span>JavaScript</span>
              <span>React</span>
              <span>Redux</span>
              <span>Node Js</span>
              <span>Express</span>
              <span>AWS</span>
              <span>SQL</span>
              <span>HTML</span>
              <span>CSS</span>
              <span>Jest</span>
              <span>GIT</span>
              <span>Github</span>
              <span>Responsive Design</span>
              <span>Communication</span>
              <span>Critical Thinking</span>
              <span>Debugging</span>
            </SkillsList>
          </SkillsContainer>
        </ContentGrid>
      </InnerContainer>
    </AboutContainer>
  );
};

export default About;
