import React from "react";
import {
  FooterContainer,
  FooterContent,
  SocialIcons,
  FooterBottom,
} from "./styles/Footer.styles";
import {
  FaLinkedin,
  FaGithub,
  // FaTwitter, FaYoutube
} from "react-icons/fa";
// import { BsFillBriefcaseFill } from "react-icons/bs";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <div className="footer-left">
          <h2>Drew Gomez</h2>
          <p>
            A <span>Full Stack Web Developer</span> building and managing
            Websites and Web Applications that lead to the success of the
            overall product. Check out some of my work in the Projects section.
          </p>
        </div>
        <div className="footer-right">
          <h3>SOCIAL</h3>
          <SocialIcons>
            <a
              href="https://www.linkedin.com/in/andrewgomez1992/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin size={30} />
            </a>
            <a
              href="https://github.com/andrewgomez1992"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub size={30} />
            </a>
            {/* <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter size={30} />
            </a> */}
            {/* <a
              href="https://youtube.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube size={30} />
            </a> */}
            {/* <a href="drew-gomez.com" target="_blank" rel="noopener noreferrer">
              <BsFillBriefcaseFill size={30} />
            </a> */}
          </SocialIcons>
        </div>
      </FooterContent>
      <FooterBottom>
        <p>
          © Copyright 2024. Made by <a href="/">Drew Gomez</a>
        </p>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
