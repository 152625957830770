import React from "react";
import {
  ContactContainer,
  Form,
  Input,
  TextArea,
  SubmitButton,
} from "./styles/Contact.styles";
import { HeaderContainer, Title } from "./styles/About.styles";

const Contact = () => {
  return (
    <ContactContainer id="contact">
      <HeaderContainer>
        <Title>
          <p>CONTACT</p>
        </Title>
        <div>
          Below you can leave your information and I'll get back to you right
          away
        </div>
      </HeaderContainer>
      <Form
        method="POST"
        action="https://getform.io/f/407b4886-1beb-4682-84ad-41ddf78303a0"
      >
        <Input
          type="text"
          placeholder="Name"
          name="name"
          autoComplete="name"
          required
        />
        <Input
          type="email"
          placeholder="Email"
          name="email"
          autoComplete="email"
          required
        />
        <TextArea
          placeholder="Message"
          name="message"
          rows="6"
          autoComplete="off"
          required
        ></TextArea>
        <SubmitButton>Let's join forces</SubmitButton>
      </Form>
    </ContactContainer>
  );
};

export default Contact;
