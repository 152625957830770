import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import {
  HomeContainer,
  ContentContainer,
  IntroText,
  Description,
  ButtonWrapper,
  ProjectsButton,
} from "./styles/Home.styles";
import { scrollToSection } from "../util/scrollToSection";
import DynamicGreeting from "../util/dynamicGreeting";

const Home = () => {
  return (
    <HomeContainer id="home">
      <ContentContainer>
        <IntroText>
          <DynamicGreeting />
        </IntroText>
        <Description>
          I craft websites and apps that solve problems, deliver results, and
          create seamless, impactful experiences. Let’s build something
          exceptional.
        </Description>
        <ButtonWrapper>
          <ProjectsButton onClick={() => scrollToSection("projects")}>
            PROJECTS
            <span className="arrow-icon">
              <HiArrowNarrowRight />
            </span>
          </ProjectsButton>
        </ButtonWrapper>
      </ContentContainer>
    </HomeContainer>
  );
};

export default Home;
