import React from "react";
import { WorkContainer, WorkWrapper } from "./styles/Work.styles";

import novaai from "../assets/novaai.png";
import threejs from "../assets/threejs.png";
// import flix from "../assets/flix.png";
import vanish from "../assets/vanishcandles.png";
// import airtravelmobile from "../assets/airtravelmobile.png";
// import flixmobile from "../assets/flixmobile.png";
import planeatrepeat from "../assets/planeatrepeat.png";
// import mobilethreejs from "../assets/mobilethreejs.png";
import { HeaderContainer, Title } from "./styles/About.styles";
import ProjectShowcase from "./ProjectShowcase";

const Work = () => {
  const projectList = [
    {
      title: "Plan. Eat. Repeat.",
      description:
        "Plan Eat Repeat is a full-stack meal planning app built with React, Node.js, and Auth0, leveraging AWS S3 for image storage, a relational database for real-time meal planning and recipe management, and Redux for state management, offering features like a cookbook, chef assignments, calendar integration, and dietary preference customization.",
      buttonText: "Coming Soon",
      buttonLink: "https://myadmin-nine.vercel.app/",
      screenContent: <img src={planeatrepeat} alt="Project" />,
      // mobileImage: airtravelmobile,
    },
    {
      title: "Vanish Candles",
      description:
        "Vanish Candles combines 100% organic soy wax with a thoughtfully designed, sleek online storefront powered by Vite, React, and Framer Motion. The modern platform showcases the handmade elegance of each candle while delivering a seamless and visually stunning shopping experience.",
      buttonText: "Coming Soon",
      buttonLink: "https://vanish-candles.vercel.app/",
      screenContent: <img src={vanish} alt="Project" />,
      // mobileImage: flixmobile,
    },
    {
      title: "Nova AI",
      description:
        "Nova AI is a conversational AI chat application rebuilt using React, Vite, and Google’s Generative AI library. It features an intuitive interface with support for data visualization through Highcharts, responsive design elements, and dynamic interactions, providing an engaging and efficient way to interact with AI.",
      buttonText: "Demo",
      buttonLink: "https://deployed-ari-ai.vercel.app/",
      screenContent: <img src={novaai} alt="Project" />,
      // mobileImage: airtravelmobile,
    },
    {
      title: "T-Shirt Customizer",
      description:
        "An app that uses three.js to display a customizable t-shirt by uploading logos.",
      buttonText: "Demo",
      buttonLink: "https://threejs-tshirt-ai-2tx6.vercel.app",
      screenContent: <img src={threejs} alt="Project" />,
      // mobileImage: mobilethreejs,
    },
  ];

  return (
    <WorkContainer id="projects">
      <WorkWrapper>
        <HeaderContainer>
          <Title>
            <p>PROJECTS</p>
          </Title>
          <div>
            Below you will find some of the projects I've worked on or am
            currently working on
          </div>
        </HeaderContainer>
        <div>
          {projectList.map((project, index) => (
            <ProjectShowcase
              key={index}
              projectTitle={project.title}
              projectDescription={project.description}
              buttonText={project.buttonText}
              buttonLink={project.buttonLink}
              screenContent={project.screenContent}
              mobileImage={project.mobileImage}
            />
          ))}
        </div>
      </WorkWrapper>
    </WorkContainer>
  );
};

export default Work;
