import React, { useState } from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import blackhat from "../assets/cowboyvector.png";
import {
  NavbarContainer,
  LogoContainer,
  Menu,
  Hamburger,
  MobileMenu,
  SocialIcons,
  HamburgerIcon,
  CloseIcon,
} from "./styles/Navbar.styles";
import { scrollToSection } from "../util/scrollToSection";

const Navbar = () => {
  const linkedInURL = "https://www.linkedin.com/in/andrewgomez1992";
  const githubURL = "https://github.com/andrewgomez1992";
  const resumeURL =
    "https://drive.google.com/file/d/1loyZohxeVkb8Bt5GixBK9ISSiObjWMcA/view?usp=sharing";

  const [nav, setNav] = useState(false);

  const handleClick = () => {
    setNav(!nav);
  };

  return (
    <NavbarContainer>
      <LogoContainer href="/">
        <img src={blackhat} alt="black-hat-logo" />
        <h1>The Coding Cowboy</h1>
      </LogoContainer>

      <Menu>
        <ul>
          <li>
            <a
              href="#home"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection("home");
              }}
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="#about"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection("about");
              }}
            >
              About
            </a>
          </li>
          <li>
            <a
              href="#projects"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection("projects");
              }}
            >
              Projects
            </a>
          </li>
          <li>
            <a
              href="#contact"
              onClick={(e) => {
                e.preventDefault();
                scrollToSection("contact");
              }}
            >
              Contact
            </a>
          </li>
        </ul>
      </Menu>

      <Hamburger onClick={handleClick}>
        {!nav ? <HamburgerIcon /> : <CloseIcon />}
      </Hamburger>

      <MobileMenu $isOpen={nav}>
        <li onClick={handleClick}>
          <a
            href="#home"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("home");
            }}
          >
            Home
          </a>
        </li>
        <li onClick={handleClick}>
          <a
            href="#about"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("about");
            }}
          >
            About
          </a>
        </li>
        <li onClick={handleClick}>
          <a
            href="#projects"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("projects");
            }}
          >
            Projects
          </a>
        </li>
        <li onClick={handleClick}>
          <a
            href="#contact"
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("contact");
            }}
          >
            Contact
          </a>
        </li>
      </MobileMenu>

      <SocialIcons>
        <ul>
          <li>
            <a href="/" onClick={() => window.open(linkedInURL, "_blank")}>
              Linkedin <FaLinkedin size={30} />
            </a>
          </li>
          <li>
            <a href="/" onClick={() => window.open(githubURL, "_blank")}>
              Github <FaGithub size={30} />
            </a>
          </li>
          <li>
            <a href="mailto:drewgomez209@gmail.com">
              Email <HiOutlineMail size={30} />
            </a>
          </li>
          <li>
            <a href="/" onClick={() => window.open(resumeURL, "_blank")}>
              Resume <BsFillPersonLinesFill size={30} />
            </a>
          </li>
        </ul>
      </SocialIcons>
    </NavbarContainer>
  );
};

export default Navbar;
